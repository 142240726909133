<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="location.organization.id"
          :filterable="true"
          :showAll="false"
          :allowNone="false"
          @organizationChanged="
            (organizationId) => {
              location.organization.id = organizationId;
              location.manager.id = null;
              location.contacts = [];
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.MANAGER')}`"
        :placeholder="$t('COMMON.MANAGER')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
      >
        <user-selector
          :placeholder="$t('COMMON.MANAGER')"
          :user="location.manager?.id"
          :filterable="true"
          :showAll="false"
          :filterOrganization="location.organization.id"
          @userChanged="
            (userId) => {
              location.manager.id = userId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.manager" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('CONTACTS.CONTACTS')}`"
        :placeholder="$t('CONTACTS.CONTACTS')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_CONTACTS)"
      >
        <contacts-selector
          :placeholder="$t('COMMON.CONTACTS')"
          :contacts="location.contacts"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :filterOrganization="location.organization.id"
          @contactsChanged="
            (contacts) => {
              location.contacts = contacts;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.contacts" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        :placeholder="$t('COMMON.NAME')"
        v-model="location.name"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.EMAIL')} (*)`"
        :placeholder="$t('COMMON.EMAIL')"
        v-model="location.email"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.email" />
    </div>

    <div class="form-wrapper full">
      <div class="row equal">
        <div class="equal-item type">
          <base-input :label="`${$t('COMMON.TYPE')}`">
            <el-select
              :label="$t('COMMON.TYPE')"
              :placeholder="$t('COMMON.TYPE')"
              v-model="location.phone_type"
              @change="
                (type) => {
                  location.phone_type = type;
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(value, key) in phoneTypesOptions"
                :key="key"
                :value="value"
                :label="$t(`COMMON.PHONE_TYPE_${value}`)"
              />
            </el-select>
          </base-input>
        </div>
        <div class="equal-item phone">
          <base-input :label="`${$t('COMMON.PHONE')}`">
            <phone-number-input
              :phoneNumber="location.phone"
              @phoneNumberChanged="
                (phone) => {
                  location.phone = phone;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.phone" />
        </div>
        <div class="equal-item extension">
          <base-input
            :label="`${$t('COMMON.EXTENSION')}`"
            v-model="location.phone_extension"
            @change="
              () => {
                onFormChanged();
              }
            "
            :inputClasses="'extension-input'"
          />
          <validation-error :errors="apiValidationErrors.phone_extension" />
        </div>
      </div>
    </div>
    <div class="form-wrapper full taxes-form">
      <base-input
        :label="`${$t('COMMON.ADDRESS')} (*)`"
        :placeholder="$t('COMMON.ADDRESS')"
        v-model="location.address"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.address" />
    </div>

    <div class="form-wrapper full">
      <base-checkbox
        @change="onFormChanged"
        v-model="location.is_municipal"
        class="mb-3"
      >
        <span class="form-control-label">
          {{ $t("LOCATIONS.IS_MUNICIPAL") }}
        </span>
      </base-checkbox>
      <validation-error :errors="apiValidationErrors.is_municipal" />
    </div>

    <!-- Excerpt -->
    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.EXCERPT')}`"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor v-model="location.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <!-- Infos marchand -->
    <div class="form-wrapper full title">
      <h3 class="labe-red">{{ $t("COMMON.INFORMATIONS_MARCHAND") }}</h3>
    </div>
    <!--
    <div class="form-wrapper taxes full">
      <taxes-selector
        :label="$t('COMMON.DEFAULT_TAXES')"
        :taxes="location.organization.taxes"
        @taxesChanged="
          (taxes) => {
            location.organization.taxes = taxes;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.taxes" />
    </div>
  -->
    <div class="form-wrapper full grid-4">
      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.LICENCE_MARCHAND')} (*)`"
          :placeholder="$t('COMMON.LICENCE_MARCHAND')"
          v-model="location.marchand_licence"
          @change="onFormChanged()"
        />
      </div>
      <div class="form-wrapper">
        <base-input
          :label="`${$t(
            'COMMON.DATE_D_EXPIRATION_DE_LA_LICENCE_MARCHAND'
          )} (*)`"
          class="dates"
          v-model="location.marchand_licence_expired_at"
          :placeholder="`${$t(
            'COMMON.DATE_D_EXPIRATION_DE_LA_LICENCE_MARCHAND'
          )} (*)`"
        >
          <flat-picker
            :config="{
              allowInput: true,
              enableTime: false,
              type: 'date',
              minDate: 'today',
              locale: $flatPickrLocale(),
              disable: [
                function (date) {
                  return date.getDay() === 0;
                },
              ],
            }"
            class="form-control datepicker"
            v-model="location.marchand_licence_expired_at"
            :placeholder="`${$t(
              'COMMON.DATE_D_EXPIRATION_DE_LA_LICENCE_MARCHAND'
            )} (*)`"
          />
        </base-input>
      </div>
      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.NUMERO_SAAQ')} (*)`"
          :placeholder="$t('COMMON.NUMERO_SAAQ')"
          v-model="location.marchand_saaq"
          @change="onFormChanged()"
        />
      </div>
      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.DATE_D_EXPIRATION_DU_NUMERO_SAAQ')} (*)`"
          class="dates"
          :placeholder="`${$t('COMMON.DATE_D_EXPIRATION_DU_NUMERO_SAAQ')} (*)`"
        >
          <flat-picker
            :config="{
              allowInput: true,
              enableTime: false,
              type: 'date',
              minDate: 'today',
              locale: $flatPickrLocale(),
              disable: [
                function (date) {
                  return date.getDay() === 0;
                },
              ],
            }"
            class="form-control datepicker"
            v-model="location.marchand_saaq_expired_at"
            :placeholder="`${$t(
              'COMMON.DATE_D_EXPIRATION_DU_NUMERO_SAAQ'
            )} (*)`"
          />
        </base-input>
      </div>
      <div class="form-wrapper full">
        <base-input
          :label="`${$t('COMMON.OPC_LICENCE')} (*)`"
          :placeholder="$t('COMMON.REVENDEUR')"
          v-model="location.marchand_opc_licence"
          @change="onFormChanged()"
        />
      </div>
    </div>

    <!-- billing address -->
    <div class="form-wrapper full title">
      <h3 class="labe-red">{{ $t("COMMON.BILLING_ADDRESS") }}</h3>
    </div>

    <div class="form-wrapper full">
      <billing-informations-form
        :billingInformationsData="location"
        :formErrors="formErrors"
        @onChangeBillingInformations="billingInformationsChanged"
      />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          location.id
            ? $t("LOCATIONS.EDIT_LOCATION")
            : $t("LOCATIONS.ADD_LOCATION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import ContactsSelector from "@/components/ContactsSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import { phoneTypesOptions } from "@/constants/common";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";
import TaxesSelector from "@/components/TaxesSelector.vue";
import flatPicker from "vue-flatpickr-component";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    BillingInformationsForm,
    TaxesSelector,
    UserSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    ContactsSelector,
    PhoneNumberInput,
    PhoneNumbersSelector,
    flatPicker,
  },

  mixins: [formMixin],

  props: ["locationData", "formErrors", "loading"],

  data() {
    return {
      location: { ...this.locationData },
      phoneTypesOptions,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      const locationData = cloneDeep(this.location);
      const me = this.$store.getters["profile/me"];
      if (!!me.organization?.id) {
        locationData.organization.id = me.organization.id;
      }
      if (!locationData.organization.id) {
        delete locationData.organization;
      }
      if (!locationData.manager.id) {
        delete locationData.manager;
      }
      if (locationData.reseller) {
        if (!locationData.reseller.id) {
          delete locationData.reseller;
        }
      }

      this.$emit("locationSubmitted", locationData);
    },
    billingInformationsChanged(billingInformations) {
      this.location = { ...this.location, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    locationData(locationData) {
      if (locationData) {
        this.location = { ...this.location, ...cloneDeep(locationData) };
      }
    },
  },
};
</script>
