<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <h3>{{ location.name }}</h3>
        </div>

        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_DELETE_LOCATIONS)"
            @click="deleteLocation"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="$currentUserCan($permissions.PERM_EDIT_LOCATIONS)"
            @click="editLocation"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ location.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ location.email }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.PHONE") }}</dt>
          <dd>
            {{ location.phone }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ location.address }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("LOCATIONS.IS_MUNICIPAL") }}</dt>
          <dd>
            <span v-if="location.is_municipal">
              {{ $t("COMMON.YES") }}
            </span>
            <span v-else>
              {{ $t("COMMON.NO") }}
            </span>
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt>{{ $t("COMMON.MANAGER") }}</dt>
          <dd>
            <users :users="location?.manager?.id ? [location.manager] : []" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="location.excerpt"></div>
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
        >
          <dt>{{ $t("CONTACTS.CONTACTS") }}</dt>
          <dd>
            <contacts :contacts="location.contacts" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="location.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="location.created_at">
            {{ $timeZoneDateFormat(location.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="location.updated_at">
            {{ $timeZoneDateFormat(location.updated_at) }}
          </dd>
        </dl>
      </div>

      <div class="mb-2 mt-4">
        <p class="labe-red">
          {{ $t("COMMON.INFORMATIONS_MARCHAND") }}
        </p>
      </div>

      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.LICENCE_MARCHAND") }}</dt>
          <dd>
            {{ location.marchand_licence }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.DATE_D_EXPIRATION_DE_LA_LICENCE_MARCHAND") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(location.marchand_licence_expired_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.NUMERO_SAAQ") }}</dt>
          <dd>
            {{ location.marchand_saaq }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.DATE_D_EXPIRATION_DU_NUMERO_SAAQ") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(location.marchand_saaq_expired_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.OPC_LICENCE") }}</dt>
          <dd>
            {{ location.marchand_opc_licence }}
          </dd>
        </dl>
      </div>

      <div class="mb-2 mt-4">
        <p class="labe-red">
          {{ $t("COMMON.BILLING_ADDRESS") }}
        </p>
      </div>

      <billing-informations-view :billingInformations="location" />
    </div>
  </div>
</template>

<script>
import Users from "@/components/Users.vue";
import Contacts from "@/components/Contacts.vue";
import BillingInformationsView from "@/components/BillingInformationsView.vue";

export default {
  name: "location-view-global",

  components: {
    Users,
    Contacts,
    BillingInformationsView,
  },

  props: ["location"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    editLocation() {
      this.$emit("onEditLocation", this.location);
    },
    deleteLocation() {
      this.$emit("onDeleteLocation", this.location);
    },
  },

  mounted() {},

  watch: {
    location(location) {},
  },
};
</script>
